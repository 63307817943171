<template>

	<div v-if="site" class="w-100 h-100 position-relative overflow-hidden">

		<div class="w-100 h-100 d-flex">

			<div class="d-flex flex-column w-100 h-100">

				<design_nav :site="site" />

				<div class="w-100 h-100 position-relative p-4 overflow-auto">

					<div v-if="pages" class="row row-cols-3 row-cols-xl-4">
						
						<div v-for="( page, page_key ) in pages" :key="'page-' + page_key" class="col mb-4 position-relative">

							<div class="mb-2 small fw-bold">
								{{ trim( page.post_title ) }}
							</div>

							<div class="card position-relative overflow-hidden iframe-container">
								<div class="ratio ratio-16x9"></div>
								<div class="position-absolute top-50 start-50 translate-middle">
									<span class="spinner-border spinner-border-sm" role="status">
										<span class="visually-hidden">Loading...</span>
									</span>
								</div>
								<iframe :data-src="preview + '?p=' + page.ID" class="position-absolute top-0 start-0 overflow-hidden" scrolling="no" style="width: 1400px; height: 787.5px; transform-origin: top left;"></iframe>
							</div>

							<router-link :to="{ name: 'site.design.page', params: { page_id: page.ID } }" class="stretched-link"></router-link>

						</div>

					</div>

				</div>

			</div>

		</div>

	</div>

</template>

<script>

import { mapGetters } from 'vuex';
import design_nav from '@/components/design_nav'
import site_service from '@/services/site_service'
import page_service from '@/services/page_service'

export default {
	name: 'site.design',

	props: {
		modelValue: [Object],
		workspace: [Object],
	},

	emits: [
		'update:modelValue',
	],

	components: {
		design_nav,
	},

	data() {
		return {
			loading: false,
			site: null,
			pages: [],
			tab: 'wireframes',
			status: 0,
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		preview()
		{
			var url = 'https://'

			if ( this.workspace ) {
				url += this.workspace.domain + '/storage/'
			}

			if ( this.site ) {
				url += this.site.slug
			}

			return url
		},

	},

	mounted()
	{
		this.site = JSON.parse( JSON.stringify( this.modelValue ) )

		this.get_pages()

		var vm = this;

		window.$( window ).on( 'resize', function() {
			vm.resizeIframe();
		});
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		async ai_design()
		{			
			this.reset_error()

			this.loading = true

			await site_service.ai_design( this.$route.params.workspace_id, this.$route.params.site_id ).then(( response ) => {

				this.site = JSON.parse( JSON.stringify( response.data ) )

				this.success = 'Updated successfully'

				this.$emit('update:modelValue', this.site)

				this.$router.push( { name: 'site.design.prototype', query: { success: true } } );

				this.version++

				this.get_pages()

			}).catch((error) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},

		async get_pages()
		{			
			this.reset_error()

			this.loading = true

			await page_service.get_wordpress( this.$route.params.workspace_id, this.$route.params.site_id, '?flatten=true' ).then(( response ) => {

				this.pages = response.data

				this.resizeIframes();

			}).catch((error) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},

		async submit( question )
		{
			this.loading = true 

			this.reset_error()

			this.site.question = question

			site_service.update( this.$route.params.workspace_id, this.$route.params.site_id, this.site ).then(( response ) => {

				this.site = JSON.parse( JSON.stringify( response.data ) )

				this.success = 'Updated successfully'

				this.$emit('update:modelValue', this.site)

				this.version++
				
				this.get_pages()

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {

				this.loading = false

			})
		},

		trim( string )
		{
			var length = 40;

			return string.length > length ? string.substring(0, length - 3) + "..." : string;
		},

		resizeIframes() 
		{
			var $ = window.$;

			var container = $( '.iframe-container' );

			if ( container.length ) {

				this.resizeIframe( container, 0 );

			} else {
				setTimeout( () => {
					this.resizeIframes()
				}, 500)
			}
		},

		resizeIframe(iframes, index) {
			var $ = window.$;

			// Return if there is no iframe at the current index
			if (!iframes[index]) return;

			var container = $(iframes[index]);
			var containerWidth = container.width();
			var scaleFactor = containerWidth / 1400;
			var iframe = container.find('iframe');
			var src = iframe.data('src');

			iframe.css({
			'transform': 'scale(' + scaleFactor + ')'
			});

			if (!iframe.hasClass('iframe-loaded')) {
				// Add the load event listener before setting the src attribute
				iframe.on('load', () => {
					// Remove the event listener to avoid memory leaks
					iframe.off('load');
					// Add the loaded class
					iframe.addClass('iframe-loaded');
					// Continue with the next iframe
					this.resizeIframe(iframes, index + 1);
				});

				// Set the src attribute which will start the loading process
				iframe.attr('src', src);
			} else {
				// If the iframe is already loaded, proceed to the next one
				this.resizeIframe(iframes, index + 1);
			}
		}
	}
}
</script>