<template>
	
	<div>

		<div class="d-flex pe-2 w-100 align-items-center justify-content-between border-bottom position-relative">

			<div class="d-flex align-items-center justify-content-between">

				<div class="d-flex align-items-center">

					<div class="p-3 px-4 rounded-0 d-flex align-items-center justify-content-center fw-bold border-end" style="width: 270px;">
						{{ limitString( site.name, 20 ) }}
					</div>

					<router-link 
						:to="{ name: 'site.design.prototype' }" class="btn p-3 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2" :class="$route.name == 'site.design.prototype' ? 'border-primary' : 'border-light'">
						<span class="material-icons-outlined me-2 d-none d-xxl-block">smart_display</span>
						Prototype
					</router-link>

					<router-link
						:to="{ name: 'site.design.wireframes' }" class="btn p-3 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2" :class="['site.design.wireframes', 'site.design.page'].includes( $route.name ) ? 'border-primary' : 'border-light'">
						<span class="material-icons-outlined me-2 d-none d-xxl-block">web</span>
						Designs
					</router-link>

					<div class="btn-group dropdown">
						<button 
						class="btn p-3 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2 dropdown-toggle v-step-content" 
						:class="( ['site.design.content.menus', 'site.design.content.header', 'site.design.content.footer'].includes( $route.name ) ? 'border-primary' : 'border-light' )" 
						type="button" 
						id="globals-dropdown" 
						data-bs-toggle="dropdown" 
						aria-expanded="false"
						>
							<span class="material-icons-outlined me-2 d-none d-xxl-block">call_to_action</span>
							Globals
						</button>
						<ul class="dropdown-menu" aria-labelledby="globals-dropdown">

							<li>
								<router-link 
								:to="{ name: 'site.design.content.menus' }"
								class="dropdown-item position-relative" 
								:class="( $route.name == 'site.design.content.menus' ? 'active' : '' )"
								>
									Menus
								</router-link>
							</li>

							<li>
								<router-link 
								:to="{ name: 'site.design.content.header' }"
								class="dropdown-item position-relative" 
								:class="( $route.name == 'site.design.content.header' ? 'active' : '' )"
								>
									Header
								</router-link>
							</li>

							<li>
								<router-link 
								:to="{ name: 'site.design.content.footer' }"
								class="dropdown-item position-relative" 
								:class="( $route.name == 'site.design.content.footer' ? 'active' : '' )"
								>
									Footer
								</router-link>
							</li>

						</ul>
					</div>

					<div class="btn-group dropdown">
						<button 
						class="btn p-3 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2 dropdown-toggle v-step-content" 
						:class="( $route.name.includes( '-template' ) ? 'border-primary' : 'border-light' )" 
						type="button" 
						id="globals-dropdown" 
						data-bs-toggle="dropdown" 
						aria-expanded="false"
						>
							<span class="material-icons-outlined me-2 d-none d-xxl-block">space_dashboard</span>
							Templates
						</button>
						<ul class="dropdown-menu" aria-labelledby="globals-dropdown">

							<li>
								<router-link 
								:to="{ name: 'site.design.content.post-template' }"
								class="dropdown-item position-relative d-flex align-items-center justify-content-between" 
								:class="( $route.name == 'site.design.content.post-template' ? 'active' : '' )"
								>
									Posts
								</router-link>
							</li>

							<li>
								<router-link 
								:to="{ name: 'site.design.content.search-template' }"
								class="dropdown-item position-relative d-flex align-items-center justify-content-between" 
								:class="( $route.name == 'site.design.content.search-template' ? 'active' : '' )"
								>
									Search
								</router-link>
							</li>

							<li>
								<router-link 
								:to="{ name: 'site.design.content.archive-template' }"
								class="dropdown-item position-relative d-flex align-items-center justify-content-between" 
								:class="( $route.name == 'site.design.content.archive-template' ? 'active' : '' )"
								>
									Archive
								</router-link>
							</li>

							<li>
								<router-link 
								:to="{ name: 'site.design.content.notfound-template' }"
								class="dropdown-item position-relative d-flex align-items-center justify-content-between" 
								:class="( $route.name == 'site.design.content.notfound-template' ? 'active' : '' )"
								>
									404
								</router-link>
							</li>

						</ul>
					</div>

					<a v-if="allow_generate" data-bs-toggle="modal" data-bs-target="#generate" class="btn p-3 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2 border-light text-info">
						<span class="material-icons-outlined me-2 d-none d-xxl-block">auto_fix_high</span>
						Regenerate with Leo
					</a>

				</div>

			</div>

			<sub_nav :site="site" />

		</div>

	</div>

</template>

<script>
import { mapGetters } from 'vuex';
import sub_nav from '@/components/sub_nav'

export default {
	name: 'components.design_nav',
	props: {
		site: Object,
		preview: String,
		inspect: Boolean,
		zoom: Number,
		show_share: Boolean,
		modelValue: [String, Number, Object, Boolean],
		allow_generate: Boolean
	},
	components: {
		sub_nav
	},
	computed: {

		...mapGetters( 'user', ['auth_user']),

		value: {
			get() {
				return this.modelValue
			},
			set( value ) {
				this.$emit('update:modelValue', value)			
			}
		},

		inspect_value: {
			get() {
				return this.inspect
			},
			set( value ) {
				this.$emit('update:inspectValue', value)			
			}
		},

		zoom_value: {
			get() {
				return this.zoom
			},
			set( value ) {
				this.$emit('update:zoomValue', value)			
			}
		},

		staging() 
		{
			var email = this.auth_user.email
			var key = this.site.key
			return this.preview + '?user=' + email + '&key=' + key

		}
	},

	methods: {
		limitString(str, maxLength) 
		{
			if (str.length > maxLength) {
				return str.slice(0, maxLength - 3) + '...';
			}
			return str;
		}
	}
}
</script>

<style>

</style>
